import { observable, action, makeObservable } from 'mobx';

class UiStore {
  isFetchingData = false;
  fetchingCount = 0;

  constructor() {
    makeObservable(this, {
      isFetchingData: observable,
      startOneFetch: action,
      finishOneFetch: action
    });
  }

  startOneFetch() {
    this.fetchingCount++;
    this.isFetchingData = true;
  }

  finishOneFetch() {
    if (this.fetchingCount > 0) {
      this.fetchingCount--;
    }
    this.isFetchingData = this.fetchingCount > 0;
  }
}

export default UiStore;