import HttpRequest from '../http-request';

const login = async (userName, password) => {
  const loginResponse = await HttpRequest.request('/api/v1/auth/login', {
    userName: userName,
    password: password
  });

  return loginResponse;
}

const me = async () => {
  const meResponse = await HttpRequest.get('/api/v1/auth/me');

  return meResponse;
}

const AuthenticationService = {
  login,
  me
}

export default AuthenticationService;