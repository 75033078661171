import React, { lazy, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import ThemedSuspense from './components/ThemedSuspense';

import { observer } from 'mobx-react';
import { MobXProviderContext } from 'mobx-react';

import AuthenticationService from './services/authentication/authentication-service';

const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/authentication/page-login'));
const CreateAccount = lazy(() => import('./pages/CreateAccount'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));


const App = observer(() => {
  const [checkingAuth, setCheckingAuth] = useState(true);

  const { rootStore } = React.useContext(MobXProviderContext);
  const { authStore } = rootStore;

  useEffect(() => {
    async function fetchMeData() {
      const userInfo = await AuthenticationService.me();

      if (userInfo) {
        authStore.setUserInfo(userInfo);
        authStore.setAuthenticated(true);
      }

      setCheckingAuth(false);
    }

    if (authStore.isAuthenticated) {
      setCheckingAuth(false);
    } else {
      fetchMeData();
    }
  });

  if (checkingAuth) {
    return (
      <ThemedSuspense />
    )
  }

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />

          <Route
            render={({ location }) => {
                if (authStore.isAuthenticated) {
                  return <Layout/>
                } else {
                    return <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: location }
                      }}
                    />
                }
              }
            }
          />

          {/* Place new routes over this */}
          {/* <Route path="/app" component={Layout} /> */}
          {/* If you have an index page, you can remothis Redirect */}
          {/* <Redirect exact from="/" to="/login" /> */}
        </Switch>
      </Router>
    </>
  )
});

export default App;