import {ACCESS_TOKEN} from '../data/constants';

const generateFullUrl = (urlPath) => {
  if (!urlPath.startsWith('/')) {
    urlPath = '/' + urlPath;
  }

  return process.env.REACT_APP_API_DOMAIN + urlPath;
}

const get = async (urlPath = '', params = {}) => {
  window.rootStore.uiStore.startOneFetch();

  const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  const url = `${urlPath}?${queryString}`;

  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  const fullUrl = generateFullUrl(url);

  try {
    const response = await fetch(fullUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authorizationValue
      }
    });

    const jsonResponse = await response.json();

    window.rootStore.uiStore.finishOneFetch();
    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  window.rootStore.uiStore.finishOneFetch();

  return null;
}

const request = async (urlPath = '', body = {}, method = 'POST') => {
  window.rootStore.uiStore.startOneFetch();

  // let url = new URL(urlPath, '');

  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  const fullUrl = generateFullUrl(urlPath);

  try {
    const response = await fetch(fullUrl, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': authorizationValue
      },
      body: JSON.stringify(body)
    });

    const jsonResponse = await response.json();

    window.rootStore.uiStore.finishOneFetch();
    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  window.rootStore.uiStore.finishOneFetch();

  return null;
}

const HttpRequest = {
  get,
  request
};

export default HttpRequest;