import { observable, action, makeObservable } from 'mobx';

class AuthStore {
  isAuthenticated = false;
  userInfo = {};

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      userInfo: observable,
      setAuthenticated: action,
      setUserInfo: action
    });
  }

  setAuthenticated(isAuthenticated = false) {
    this.isAuthenticated = isAuthenticated;
  }

  setUserInfo(userInfo = null) {
    this.userInfo = userInfo;
  }
}

export default AuthStore;