import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/css/tailwind.output.css';
import App from './App';
import { SidebarProvider } from './context/SidebarContext';
import ThemedSuspense from './components/ThemedSuspense';
import { Windmill } from '@windmill/react-ui';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'mobx-react';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';

import RootStore from './stores/root-store';


require('es6-promise').polyfill();

const rootStore = new RootStore();

window.rootStore = rootStore;

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider rootStore={rootStore}>
      <React.StrictMode>
        <SidebarProvider>
          <Suspense fallback={<ThemedSuspense />}>
            <Windmill usePreferences>
              <App />
            </Windmill>
          </Suspense>
        </SidebarProvider>
      </React.StrictMode>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
